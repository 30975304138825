@import '@snc/styles/styles.scss';

.snc-tree-entry:first-of-type {
    padding-left: 0px;
}

.node {
    display: flex;
    white-space: nowrap;
    color: black;
    height: 60px;

    .node-icon {
        display: inline-flex;
        text-align: right;
        width: 35px;
        height: 35px;
        user-select: none;
        line-height: 45px;
    }

    .node-text-container {
        display: inline-flex;
        user-select: none;
        line-height: 45px;
        max-width: calc(100% - 40px);
        padding-left: 2px;

        .node-text {
            padding: 0px 14px 0px 14px;
            max-width: 100%;
            line-height: 45px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;

            border-radius: 10px;
            border: 1px solid $grey_blue_light_color;
        }
    }
}

.node-selected {
    .node-text {
        background-color: $primary_color;
        color: white;
        border: 0px !important;
    }
}
