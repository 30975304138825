.entity-selector {
    background-color: white;
    border-radius: 50px;

    min-width: fit-content;
    width: 100%;
    max-width: 350px;

    height: 60px;

    button {
        border-radius: 50px;
        min-width: 50px;
        min-height: 50px;
    }
}

.entity-selector {
    .current-selection {
        font-size: 20px;
        font-weight: 500;
    }
}
