@import '@snc/styles/styles.scss';

.MuiButtonBase-root {
    outline: none !important;
}

.snc-mui-tabs {
    background: none !important;

    .MuiTabs-scrollButtons {
        .MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
    }

    .MuiButtonBase-root {
        outline: none !important;
        text-decoration: none !important;
    }

    .MuiAppBar-root {
        background-color: unset !important;
        box-shadow: none;
    }

    .MuiTabs-root {
        background-color: unset;
        box-shadow: 0 4px 4px -2px rgba(gray, 0.2);
        border-radius: $border-radius;

        .MuiTab-textColorPrimary.Mui-selected {
            color: $primary_color;
            font-weight: bolder;
            outline: none;
        }

        .MuiTabs-indicator {
            background-color: $primary_color;
        }
    }
}

.snc-mui-tabs-primary,
.snc-mui-tabs-primary-with-border,
.snc-mui-tabs-secondary,
.snc-mui-tabs-secondary-with-border {
    .MuiTab-textColorPrimary {
        color: rgba($color_white, 0.8);
    }

    .MuiTabs-scrollButtons {
        .MuiSvgIcon-root {
            fill: $color_white;
        }
    }

    .MuiTabs-root {
        box-shadow: 0 4px 4px -2px rgba(gray, 0.8);
        border-radius: 0px !important;

        .MuiTab-textColorPrimary.Mui-selected {
            color: $color_white;
            font-weight: bolder;
            outline: none;
        }

        .MuiTabs-indicator {
            background-color: $color_white;
            height: 4px;
        }
    }
}

.snc-mui-tabs-primary,
.snc-mui-tabs-primary-with-border {
    > .MuiTabs-root {
        background-color: $primary_color !important;
    }
}

.snc-mui-tabs-secondary,
.snc-mui-tabs-secondary-with-border {
    > .MuiTabs-root {
        background-color: $secondary_color !important;
    }

    .MuiTab-textColorPrimary.Mui-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
}

.snc-mui-tabs-primary-with-border {
    border: 1px solid $primary_color !important;
}

.snc-mui-tabs-secondary-with-border {
    border: 1px solid $secondary_color !important;
}

.snc-mui-tabs-horizontal {
    flex-grow: 1;
    width: 100%;
    padding: 0;
}

.snc-mui-tabs-vertical {
    flex-grow: 1;
    display: flex;
    height: 100%;

    .snc-mui-tabs-tabselector {
        //border-right: 1px solid $primary_color;
        box-shadow: 4px 0 10px -10px black;
    }

    .MuiTabs-vertical {
        width: 180px !important;
        min-width: 180px !important;
    }

    > .MuiTypography-root {
        width: calc(100% - 180px) !important;
        max-width: calc(100% - 180px) !important;
    }

    .snc-mui-tabs {
        > .MuiTypography-root {
            width: initial !important;
            max-width: initial !important;
        }
    }
}

.tab-container {
    height: calc(100% - 50px) !important;
}

.tab-container-content {
    height: calc(100% - 45px);
}
