@import '@snc/styles/styles.scss';

.progress-report-container {
    position: absolute;
    z-index: 1001;
    background-color: #d8d8d8;
    overflow: hidden;

    .progress-report-text {
        color: $primary_color;
        font-weight: 500;
        text-transform: uppercase;
    }

    .progress-spinner > div > div {
        background-color: $primary_color;
    }
}

.progress-container {
    position: absolute;
    z-index: 1000;
    background-color: #d8d8d854;
    border-radius: 4px;
    left: 0;
    top: 0;
    overflow: hidden;
}

.progress-report {
    .action-text {
        font-weight: 500;
    }

    .progress-report-text {
        color: $primary_color;
        font-weight: 500;
        text-transform: uppercase;
    }

    .progress-spinner > div > div {
        background-color: $primary_color;
    }
}
