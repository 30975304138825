@import '@snc/styles/styles.scss';

.snc-accordeon {
    .MuiExpansionPanel-root {
        background: $grey_blue_dark_color !important;
        color: $white !important;

        .icon-white {
            color: $white !important;
        }

        .MuiListItem-gutters {
            padding-left: 28px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .MuiListItem-root {
            &.Mui-selected {
                background-color: $color_white !important;
                color: $grey_blue_dark_color !important;

                .icon-white {
                    color: $grey_blue_dark_color !important;
                }
            }
        }

        .MuiListItem-gutters:hover {
            background-color: rgba($color_white, 0.1);
        }

        &.MuiPaper-elevation1 {
            box-shadow: none;
            // border-top: 1px rgba($color_white, 0.3) solid;
        }

        &.MuiPaper-elevation1:first-of-type {
            border-top: none;
        }
    }

    .MuiExpansionPanel-root.Mui-expanded {
        margin: 0px 0 !important;
        font-weight: 600;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
        // border-bottom: 1px rgba($color_white, 0.1) solid;
    }

    .MuiExpansionPanelSummary-root {
        padding: 6px 24px 6px 24px !important;

        &.Mui-expanded {
            padding: 6px 24px 0px 24px !important;
        }
    }
}
