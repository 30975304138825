@import '@snc/styles/styles.scss';

.snc-toggle {
    border-color: rgba($color_white, 0.3) !important;
    background-color: transparent !important;
    display: block !important;

    .MuiToggleButton-root {
        color: rgba($color_white, 0.6) !important;
        border-color: rgba($color_white, 0.3) !important;
        background-color: rgba($secondary_color, 0.7) !important;

        &.Mui-selected {
            background-color: $secondary_color !important;
            color: $color_white !important;
            font-weight: 600;
        }
    }
}

@include media-breakpoint-down(sm) {
    .snc-toggle {
        display: flex !important;
        flex-direction: column;

        .MuiToggleButton-root {
            border-radius: 4px !important;
            margin-bottom: 1px;
        }
    }
}
